import { SxProps } from "@mui/system";
import React, { createContext } from "react";

export interface IGlobalConfig {
  logo: {
    src: string;
    sx?: SxProps;
    textOnly?: boolean;
  };
  languages: LanguageSelectOptions;
}

export const GlobalConfigContext = createContext<IGlobalConfig | undefined>(
  undefined
);

export default function GlobalConfigProvider({
  children,
  value,
}: {
  value: IGlobalConfig;
  children: React.ReactNode;
}) {
  return (
    <GlobalConfigContext.Provider value={value}>
      {children}
    </GlobalConfigContext.Provider>
  );
}
