import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import { Box, Link, Stack, Typography } from "@mui/material";
import { MUIStyledCommonProps, styled } from "@mui/system";
import { useLocation } from "react-router-dom";

const LogoContainer = styled(Link)`
  transition: transform 0.2s ease-in-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
`;

export default function Logo({ sx }: MUIStyledCommonProps) {
  const location = useLocation();
  const { logo } = useGlobalConfig();
  const LogoContent = (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${logo.src})`,
          backgroundRepeat: "no-repeat",
          width: 40,
          height: 40,
          paddingRight: "3rem",
        }}
      />
      <Typography variant="h4" sx={{ color: "primary.dark" }}>
        {__APP_NAME__}
      </Typography>
    </Stack>
  );

  return location.pathname !== "/payment" ? (
    <LogoContainer href="/" sx={sx}>
      {LogoContent}
    </LogoContainer>
  ) : (
    <Box sx={{ ...sx, userSelect: "none" }}>{LogoContent}</Box>
  );
}
